.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: var(--height-nav);
  padding: 1rem 10%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 5;
}

.header::before {
  content: "";
  top: 0;
  left: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(50px);
  z-index: -1;
}

.header::after {
  content: "";
  top: 0;
  left: -100%;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: -1;
  background: linear-gradient(
    90deg,
    transparent,
    rgba(255, 255, 255, 0.6),
    transparent
  );
  transition: 0.8s;
}

.header:hover::after {
  left: 100%;
}

.logo {
  font-size: 1.5rem;
  color: #fff;
  text-decoration: none;
  font-weight: 700;
}

.nav-item {
  position: relative;
  color: #fff;
  font-size: 1rem;
  font-weight: 500;
  line-height: 25px;
  letter-spacing: -0.13px;
  text-decoration: none;
  margin-left: 2.5rem;
  transition: all 0.5s ease;
}

.nav-item:hover {
  color: skyblue;
}

.icons {
  position: absolute;
  right: 5%;
  font-size: 2.3rem;
  color: #fff;
  cursor: pointer;
  display: none;
}

#check {
  display: none;
}

@media (max-width: 992px) {
  .header {
    padding: 1.3rem 5%;
  }
}

@media (max-width: 768px) {
  .icons {
    display: inline-flex;
  }

  #check:checked ~ .icons #menu-icon {
    display: none;
  }

  .icons #close-icon {
    display: none;
  }

  #check:checked ~ .icons #close-icon {
    display: block;
  }

  .navbar {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    height: 0;
    background: rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(50px);
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.1);
    overflow: hidden;
    transition: all 0.3s ease;
  }

  #check:checked ~ .navbar {
    height: 20.7rem;
  }

  .nav-item {
    display: block;
    font-size: 1.1rem;
    margin: 1.5rem 0;
    text-align: center;
    transform: translateY(-50px);
    opacity: 0;
    transition: all 0.3s ease;
  }

  #check:checked ~ .navbar a {
    transform: translateY(0);
    opacity: 1;
    transition-delay: calc(0.15s * var(--i));
  }
}
