.gif {
    --gifShadow-size: var(--gridList-gap, 6px);
    margin-bottom: .5em;
    position: relative;
    height: 300px;
}

.gif-link {
    color: #fff;
    text-decoration: none;
    position: relative;
    width: 100%;
    height: 100%;
}

.gif h4 {
    background-color: rgba(0, 0, 0, 0.3);
    font-size: 12px;
    color: #fff;
    margin: 0;
    position: absolute;
    top: 0;
    padding: 0.2em;
}

.gif img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    vertical-align: top;
    border: 1px solid black
}

