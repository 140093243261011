.container {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
}

.main-content {
    flex: 3;
    padding: 0.5em;
    height: 100%;
}

.sidebar {
    flex: 1;
    padding: 20px;
    border-left: none;
}

/* Responsive */
@media (max-width: 768px) {
    .main-content, .sidebar {
        flex-basis: 100%;
        border-left: 1px solid #ccc;
    }

    .sidebar {
        border-top: none;
    }
}